























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Enum from "@/utils/enum";
import { redirect } from "@/utils/redirect";
import { Authorization } from "@/typings/client.typings";
import {
  AuthMessages,
  AuthrorizationPaths,
  LogoutActions,
  QueryParameterNames,
} from "@/store/modules/authorization/constants";
import {
  AuthenticationResultStatus,
  AuthorizationModule,
  State,
} from "@/store/modules/authorization";

@Component
export default class Logout extends Vue {
  // #region Data ---------------------------------------------------------------------------------------

  // Component Properties -----------------------------
  @Prop({
    required: true,
    validator: (value) => Enum.IsValid(LogoutActions, value),
  })
  readonly action!: LogoutActions;

  // Consts Properties --------------------------------

  // Computed Properties ------------------------------

  // Local variables ----------------------------------

  alertType: Authorization.AlertType = Authorization.AlertType.Info;
  message = "";
  showBackButton = false;

  // #endregion

  // #region Watchers -----------------------------------------------------------------------------------
  // #endregion

  // #region Methods ------------------------------------------------------------------------------------

  // Life Cycles --------------------------------------

  async created(): Promise<void> {
    switch (this.action) {
      case LogoutActions.Logout:
        this.message = AuthMessages.ProcessingLogout;
        this.alertType = Authorization.AlertType.Info;
        await this.logout(this.getReturnUrl());
        break;

      case LogoutActions.LogoutCallback:
        this.message = AuthMessages.ProcessingLogoutCallback;
        this.alertType = Authorization.AlertType.Info;
        await this.processLogoutCallback();
        break;

      case LogoutActions.LoggedOut:
        this.message = AuthMessages.LoggedOut;
        this.alertType = Authorization.AlertType.Success;
        this.showBackButton = true;
        break;

      default:
        throw new Error(`Invalid action '${this.action}'.`);
    }
  }

  // Components Events --------------------------------
  // @Change
  // ...
  // @Click
  // ...

  // Local methods ------------------------------------

  getReturnUrl(state?: State): string {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);

    if (
      fromQuery &&
      !fromQuery.startsWith("/") &&
      !fromQuery.startsWith(`${window.location.origin}/`)
    ) {
      throw new Error(
        "Invalid return url. The return url needs to have the same origin as the current page."
      );
    }

    return (
      (state && state.returnUrl) ||
      fromQuery ||
      `${window.location.origin}${AuthrorizationPaths.LoggedOut}`
    );
  }

  async logout(returnUrl: string): Promise<void> {
    
    if (AuthorizationModule.isAuthenticated) {
      const result = await AuthorizationModule.signOutAsync({ returnUrl });
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;

        case AuthenticationResultStatus.Success:
          redirect(returnUrl);
          break;

        case AuthenticationResultStatus.Fail:
          this.message = result.message || "";
          break;

        default:
          throw new Error(
            `Invalid authentication status result: '${result.status}'`
          );
      }
    } else {
      this.message = AuthMessages.LoggedOut;
    }
  }

  async processLogoutCallback(): Promise<void> {
    const result = await AuthorizationModule.completeSignOutAsync(
      window.location.href
    );

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error("Should not redirect.");

      case AuthenticationResultStatus.Success:
        redirect(this.getReturnUrl(result.state));
        break;

      case AuthenticationResultStatus.Fail:
        this.message = result.message || "";
        break;

      default:
        throw new Error(
          `Invalid authentication result status: '${result.status}'.`
        );
    }
  }

  // #endregion
}
